import { ref, onMounted, getCurrentInstance } from 'vue';
export default {
  name: 'Counter',
  props: {
    number: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    const animatedValue = ref(0);
    const {
      proxy
    } = getCurrentInstance();
    const startCountAnimation = value => {
      const start = 0;
      const end = value;
      const duration = 3000;
      const steps = 100;
      const stepValue = (end - start) / steps;
      let currentStep = 0;
      const interval = setInterval(() => {
        if (currentStep >= steps) {
          clearInterval(interval);
        } else {
          currentStep++;
          animatedValue.value = Math.round(start + currentStep * stepValue);
        }
      }, duration / steps);
    };
    onMounted(() => {
      proxy.$emitter.on('stats-animation', data => {
        startCountAnimation(props.number);
      });
    });
    return {
      animatedValue
    };
  }
};