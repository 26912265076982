import domReady from '@roots/sage/client/dom-ready';

import {createApp} from 'vue';
import App from './vue/App.vue';

import axios from 'axios'
import mitt from 'mitt'

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';

import {Emit} from './vue/directives/emit.js';
import {Intersect} from './vue/directives/intersect.js';
import {ScrollTo} from './vue/directives/scrollto.js';

import {example} from './vue/filters/example.js';

import {scrollTo} from './vue/mixins/ScrollTo.js';

import {i18n} from './vue/i18n'

import store from './vue/store';

domReady(async () => {
  const app = createApp(App);

  app.config.globalProperties.$axios = axios

  app.config.globalProperties.$axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

  let token = document.head.querySelector('meta[name="csrf-token"]');

  if (token) { // X-CSRF-TOKEN
    app.config.globalProperties.$axios.defaults.headers.common['X-WP-Nonce'] = token.content;
  } else {
    console.error('CSRF token not found');
  }

  app.config.globalProperties.$emitter = mitt()

  // Apply global mixin and directives
  app.mixin(scrollTo);

  app.directive('emit', Emit);
  app.directive('intersect', Intersect);
  app.directive('scrollto', ScrollTo);

  // Add global filters
  app.config.globalProperties.$filters = {
    example
  };

  // Use i18n and store
  app.use(i18n);
  app.use(store);

  // Mount application
  app.mount('#app');

  const menuItems = document.querySelectorAll('.menu-item-has-children > a');

  function handleMenuClick(event) {
    event.preventDefault();
    const subMenu = this.nextElementSibling;
    const isOpen = subMenu.classList.contains('open');

    if (!isOpen) {
      closeAllMenus();
      openMenu(subMenu);
      this.classList.add('rotate');
    } else {
      closeMenu(subMenu);
      this.classList.remove('rotate');
    }
  }

  function closeAllMenus() {
    document.querySelectorAll('.sub-menu').forEach(menu => {
      closeMenu(menu);
    });

    document.querySelectorAll('.menu-item-has-children > a').forEach(arrow => {
      arrow.classList.remove('rotate');
    });
  }

  function openMenu(menu) {
    menu.classList.add('open');
    menu.style.maxHeight = menu.scrollHeight + 'px';
  }

  function closeMenu(menu) {
    menu.classList.remove('open');
    menu.style.maxHeight = '0';
  }

  function applyAccordion() {
    if (window.innerWidth <= 768) {
      menuItems.forEach(item => {
        item.addEventListener('click', handleMenuClick);
      });
    } else {
      menuItems.forEach(item => {
        item.removeEventListener('click', handleMenuClick);
      });
      closeAllMenus(); // Reset the state for desktop view
    }
  }

  applyAccordion();

  window.addEventListener('resize', applyAccordion);
});

// Enable hot module replacement if available
import.meta.webpackHot?.accept(console.error);
