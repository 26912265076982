export const Intersect = {
  beforeMount(el, binding) {
    const { emit = '', allTime = false, options = {} } = binding.value || {};

    if (!emit) {
      console.warn('Emit event is not defined in binding value');
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (allTime) {
          binding.instance.$emitter.emit(emit, entry);
        } else if (entry.isIntersecting) {
          binding.instance.$emitter.emit(emit, entry);

          observer.unobserve(el);
        }
      },
      options
    );

    el.__observer__ = observer;
    observer.observe(el);
  },
  beforeUnmount(el) {
    if (el.__observer__) {
      el.__observer__.disconnect();
      delete el.__observer__;
    }
  }
};
