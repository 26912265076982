import { createI18n } from 'vue-i18n';

import en from '../../../translations/en.json';
import pl from '../../../translations/pl.json';

const messages = {
  en,
  pl,
};

export const i18n = createI18n({
  locale: 'pl',
  fallbackLocale: 'en',
  messages,
  legacy: false
});
