export default {
  data: () => {
    return {
      loading: false
    }
  },

  methods: {
    Loading () {
      this.loading = !this.loading
    }
  }
}
