export default {
  methods: {
    MessageAlert() {
      let {
        type = 'info',
        message = '',
        duration = 5000,
        offset = 20,
        customClass = '',
        showClose = false
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      ElMessage({
        message,
        type,
        duration,
        offset,
        customClass,
        showClose
      });
    }
  }
};